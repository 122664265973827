<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <template v-if="bannerImage !== null">
        <div class="blog-banner__wrap">
          <div
            class="blog-banner"
            :style="{
              background:
                'linear-gradient(180deg, rgba(7, 11, 50, 0) 40.6%, rgba(7, 11, 50, 0.3) 100%), url(' +
                bannerImage.url +
                ') no-repeat',
            }"
          ></div>
          <p
            class="blog-name mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
            :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
          >
            <span v-if="data">{{ data.title }}</span>
          </p>
        </div>
      </template>
      <template v-else>
        <v-card flat color="primary" height="300" class="d-flex">
          <v-card
            flat
            color="secondary"
            height="89"
            class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
          >
            <p
              class="mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
              :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
            >
              <span v-if="data">{{ data.title }}</span>
            </p>
          </v-card>
        </v-card>
      </template>
      <v-container :class="$vuetify.breakpoint.xsOnly ? 'pa-8' : 'px-8 py-71'">
        <v-row>
          <v-col cols="12" md="12" class="e-col">
            <div v-html="data.details" v-if="data"></div>
          </v-col>
        </v-row>
        <p
          class="font-24 line-h-37 font-weight--bold text-color-secondary-100 text-center mb-24"
        >
          Continue reading from our blog…
        </p>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="e-col"
            v-for="(item, index) in data.other_blogData"
            :key="index"
          >
            <div class="blog-wrap bg-color-grey-200">
              <div
                class="image__wrap"
                v-if="item.public_primary_image !== null"
              >
                <img :src="item.public_primary_image.url" alt="" />
              </div>
              <div v-else class="width-180 height-180 bg-color-grey-200"></div>
              <div class="content">
                <p
                  class="font-18 line-h-22 font-weight--bold text-color-secondary-100 letter-s-25"
                >
                  {{ item.title }}
                </p>
                <a
                  :href="`/blog-post/${item.slug}`"
                  class="font-14 line-h-14 font-weight--bold text-color-secondary-100 text-decoration-none"
                >
                  <span class="mr-2">Read Post</span>
                  <img src="@/assets/images/blue-arrow.svg" />
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "AboutUs",
  components: { TheHeader, TheFooter },
  data: () => ({
    data: null,
    bannerImage: null,
  }),
  created() {
    this.getBlogById();
  },
  methods: {
    async getBlogById() {
      let slug = this.$router.currentRoute.params.slug;
      await this.$store
        .dispatch("static/BLOG_BY_ID", { slug: slug })
        .then((res) => {
          if (res.result === "success") {
            this.data = res.data;
            this.setBannerImage();
          }
        });
    },

    setBannerImage() {
      if (
        this.data.public_images &&
        Object.keys(this.data.public_images).length > 0
      ) {
        this.bannerImage = this.data.public_images;
      }
    },
  },
};
</script>
<style></style>
